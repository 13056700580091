.link-icons-container {
  display: flex;
  justify-content: center;
  padding-top: 0.5em;
}

a {
  color: red;
}

#footer-container {
  background-color: #1f2023;
  height: 3em;

  width: 100vw;
}

#footer {
  border: pink solid 1px;
}
