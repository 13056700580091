/* Style the video: 100% width and height to cover the entire window */
#myVideo {
  width: 100%;
  animation-fill-mode: forwards;
  animation-name: grow;
  animation-duration: 4s;
  /*animation-iteration-count: infinite;*/
}

#container {
  background-color: black;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  margin: 0;
}

@keyframes grow {
  0% {
    width: 10vw;
  }
  25% {
  }
  50% {
  }
  75% {
  }
  100% {
    width: 100vw;
  }
}

@media (min-width: 768px) {
  @keyframes grow {
    0% {
      width: 10vw;
    }
    25% {
    }
    50% {
    }
    75% {
    }
    100% {
      width: 80vw;
    }
  }
}

@media (min-width: 992px) {
  @keyframes grow {
    0% {
      width: 10vw;
    }
    25% {
    }
    50% {
    }
    75% {
    }
    100% {
      width: 60vw;
    }
  }
}

@media (min-width: 1200px) {
  @keyframes grow {
    0% {
      width: 10vw;
    }
    25% {
    }
    50% {
    }
    75% {
    }
    100% {
      width: 40vw;
    }
  }
}
